import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import Home from "./components/home/Home";
import Subscribed from "./components/subscribed/Subscribed";

import Campaign from "./components/Campaign";
import CampaignExpired from "./components/Campaign/CampaignExpired";

import Promosheet from "./components/Promosheet";

import Offers from "./components/offers/Offers";
import PageNotFound from "./components/PageNotFound";

import MySubscription from "./components/MySubscription/MySubscription";
import Diwali2024 from "./components/Diwali/Diwali2024";

const routing = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/signup",
    element: <App />,
  },
  {
    path: "/subscription-confirmation",
    element: <Subscribed />,
  },
  {
    path: "/campaign",
    element: <Campaign />,
  },
  {
    path: "/promosheet",
    element: <Promosheet />,
  },
  {
    path: "/offers",
    element: <Offers />,
  },
  {
    path: "/page-not-found",
    element: <PageNotFound />,
  },
  {
    path: "/campaign-expired",
    element: <CampaignExpired />,
  },
  {
    path: "/my-subscription",
    element: <MySubscription />,
  },
  {
    path: "/diwali2024",
    element: <Diwali2024 />,
  }, 
  {
    path: "*",
    element: <Navigate to="/signup" replace />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={routing} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
