import React, { useState } from "react";

function Diwali2024() {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="iframe-container" style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "18px",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <iframe
        src={`https://docs.google.com/gview?embedded=true&url=https://heartyyfresh-prod.s3.us-west-1.amazonaws.com/promotion_banner/Fremont_Apni_Mandi_Weekend_Sale_11th_oct__compressed.pdf`}
        onLoad={handleLoad}
        style={loading ? { visibility: "hidden" } : { visibility: "visible" }}
      />
    </div>
  );
}

export default Diwali2024;
